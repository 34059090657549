import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/aftersales/elsa/',
        component: () => import('@/layout/AftersalesElsaLayout.vue'),
        redirect: '/aftersales/elsa/menunggu-approval',
        children: [
            {
                path: 'menunggu-approval',
                name: 'WaitingApprovalView',
                component: () => import('@/views/AftersalesElsaWaitingApprovalView.vue'),
            },
            {
                path: 'ditolak',
                name: 'RejectedView',
                component: () => import('@/views/AftersalesElsaRejectedView.vue'),
            },
            {
                path: 'selesai',
                name: 'ApprovedView',
                component: () => import('@/views/AftersalesElsaApprovedView.vue'),
            },
            {
                path: 'jadwal-elsa',
                name: 'ScheduleView',
                component: () => import('@/views/AftersalesElsaScheduleView.vue'),
            },
            {
                path: 'jadwal-elsa/buat',
                name: 'CreateView',
                component: () => import('@/views/AftersalesElsaCreateView.vue'),
            },
            {
                path: 'jadwal-elsa/edit',
                name: 'EditView',
                component: () => import('@/views/AftersalesElsaEditView.vue'),
            },
            {
                path: 'detail',
                name: 'DetailView',
                component: () => import('@/views/AftersalesElsaDetailView.vue'),
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory((import.meta.env || process.env).BASE_URL),
    routes,
});
export default router;
